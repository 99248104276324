import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RoleBaseAccessControlService } from './role-base-access-control.service';

export const rbacGuard = (permissionId: number): CanActivateFn => {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const rbacService = inject(RoleBaseAccessControlService);
    const router = inject(Router);
    if (!rbacService.hasPermission(permissionId)) {
      router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  };
};

export const rbacSomeGuard = (permissionIds: number[]): CanActivateFn => {
  return () => {
    const rbacService = inject(RoleBaseAccessControlService);
    const router = inject(Router);
    if (!rbacService.hasSomePermission(permissionIds)) {
      router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  };
};

export const rbacEveryGuard = (permissionIds: number[]): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const rbacService = inject(RoleBaseAccessControlService);
    const router = inject(Router);
    if (!rbacService.hasEveryPermission(permissionIds)) {
      router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  };
};
